<template>
    <div class="new_stu_report">
        <div class="top flex-align">
            <img src="@/assets/report/redLogo.png" alt="" class="logo">
        </div>
        <div class="menu flex-align-between">
            <div class="download flex-align">
                <i class="el-icon-download" @click="getUserpdf" v-if="reportStatus == 0"> 生成个人报告</i>
                <span v-if="reportStatus == 1"> 报告生成中 <i class="el-icon-loading"></i></span>
                <i class="el-icon-download" @click="downloadPdf('btn')" v-if="reportStatus == 2"> 下载个人报告</i>
            </div>
            <div class="term">
                <span>学期：{{ $route.query.termKeyName }}</span>
                <span>报告时间：{{ currentTime }}</span>
            </div>
        </div>
        <div class="userinfo flex-column-center">
            <span>{{ userInfo.userName }}同学{{ reportName }}报告</span>
            <span>{{ userInfo.schoolName }}{{ userInfo.className }}</span>
        </div>

        <div class="report_box">
            <div class="week flex-align-center">
                <div>
                    {{ $route.query.termKeyName }}{{ reportName }}：
                    <span>{{ status[userComment.integrationLevel] }}</span>
                </div>
            </div>
            <div class="secondary">
                <div class="Recommended_level">CCPT书画等级考试推荐：<span style="color: #FF9900;">{{ ccptLevel }}</span></div>
                <div class="transcend_ranking">
                    <img src="@/assets/report/zan.png" alt="">
                    恭喜，你已经超过全国{{ rankNumber }}%的同学了~
                </div>
            </div>
            <div class="report_qrcode">
                <vue-qr :text="Top_up_balance" :size="300" :logoScale="0.3"></vue-qr>
            </div>
            <!-- <div class="week">
                <div class="Recommended_level">CCPT书画等级考试推荐：一级</div>
                <div class="transcend_ranking">
                    <img src="@/assets/report/zan.png" alt="">
                    恭喜，你已经超过全国{{ rankNumber }}%的同学了~
                </div>
            </div> -->
            <div class="all_table">
                <el-table :data="tableData" style="width: 100%" border :span-method="arraySpanMethod"
                    :cell-style="cellStyle">
                    <el-table-column label="作品完整度" label-class-name="header_lightgreen" header-align="center">
                        <template slot-scope="scope">
                            {{ scope.$index ? scope.row.integrity : computeLevel(scope.row.integrity) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="居中占位" label-class-name="header_green">
                        <template slot-scope="scope">
                            {{ computeLevel(scope.row.centerPos) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="占格大小" label-class-name="header_lightgreen">
                        <template slot-scope="scope">
                            {{ computeLevel(scope.row.gridSize) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="字形重心" label-class-name="header_green">
                        <template slot-scope="scope">
                            {{ computeLevel(scope.row.glyphGrav) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="组合关系" label-class-name="header_lightgreen">
                        <template slot-scope="scope">
                            {{ computeLevel(scope.row.comboRel) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="收放关系" label-class-name="header_green">
                        <template slot-scope="scope">
                            {{ computeLevel(scope.row.rewindRel) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="多画等距" label-class-name="header_lightgreen">
                        <template slot-scope="scope">
                            {{ computeLevel(scope.row.multiDist) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="笔画关系" label-class-name="header_green">
                        <template slot-scope="scope">
                            {{ computeLevel(scope.row.strokeRel) }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="workLis flex-align-between">
                <div class="item flex-align-center" v-for="(item, index) in workList" :key="index">
                    <img :src="item" alt="">
                </div>
            </div>
            <div class="wordList">
                <div class="word_item flex-align-between" v-for="(item, index) in wordList" :key="index">
                    <div class="left flex-align-center">
                        <div class="wordImg">
                            <img src="@/assets/assistant/tian.png" />
                            <img :src="item.wordShadowCover" />
                            <img :src="item.resFilename" />
                            <img :src="item.wordSkillCover" />
                        </div>
                    </div>
                    <div class="right">
                        <el-table :data="item.info" style="width: 99.99%" border :span-method="arraySpanMethod2"
                            :cell-style="cellStyle2">
                            <el-table-column v-for="items in item.head" :key="items.prop" :prop="items.prop"
                                :label="items.name" label-class-name="header_green" header-align="center">
                                <template slot-scope="scope">
                                    <div v-if="scope.$index == 0">
                                        {{ computeLevel(scope.row[items.prop]) }}
                                    </div>
                                    <div v-if="scope.$index == 1">
                                        <div v-if="scope.row.type == 1">
                                            {{ scope.row.centerPos }}
                                        </div>
                                        <div v-else>
                                            <ul>
                                                <li v-for="(item, index) in  JSON.parse(scope.row.centerPos)">
                                                    <!-- @click="showImagePreview(item.urls)" -->
                                                    <!-- :class="{ ImgHighlight: item.urls }" -->
                                                    <span>{{ item.sug }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- {{ scope.$index ? scope.row.centerPos : computeLevel(scope.row[items.prop]) }} -->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <!-- <div class="box"></div> -->
        </div>
        <div class="demo-image__preview" v-show="false">
            <el-image style="width: 100px; height: 100px" :src="url" :preview-src-list="srcList" ref="imageRef"></el-image>
        </div>

    </div>
</template>
<script>
import moment from "moment";
import vueQr from "vue-qr";
export default {
    data() {
        return {
            tableData: [],
            userInfo: {
                userId: '',
                userName: '',
                schoolName: '',
                className: '',
            },
            currentTime: '',
            workList: [],
            wordList: [],
            userComment: '',
            status: {
                '4': '优秀',
                '3': '良好',
                '2': '合格',
                '1': '待提升'
            },
            rankNumber: '',
            reportName: '',
            // termList: [],
            // reportList: [
            //     {
            //         value: 1,
            //         label: '前测报告'
            //     },
            //     {
            //         value: 2,
            //         label: '后测报告'
            //     },
            //     {
            //         value: 3,
            //         label: '月测报告'
            //     }
            // ],
            // monthList: [],
            // termValue: '',
            reportValue: 1,
            monthValue: '',
            columns: [
                { prop: 'centerPos', name: '居中占位' },
                { prop: 'gridSize', name: '占格大小' },
                { prop: 'glyphGrav', name: '字形重心' },
                { prop: 'rewindRel', name: '收放关系' },
                { prop: 'multiDist', name: '多画等距' },
                { prop: 'strokeRel', name: '笔画关系' },
                { prop: 'comboRel', name: '组合关系' },
            ],
            reportStatus: 0,
            time: null,
            Top_up_balance: '',
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            srcList: [
                'https://cnuzi-resource.oss-cn-beijing.aliyuncs.com/word-identify-score/fzkai/%E5%A4%A7-%E5%AF%B9%E6%AF%94.png?Expires=1726474216&OSSAccessKeyId=LTAI4G56MHdEKBUNCHeRnQVi&Signature=bw1QwEZPNwjz28pzKuKS4DNEkxI%3D',
                'https://cnuzi-resource.oss-cn-beijing.aliyuncs.com/word-identify-score/fzkai/%E5%A4%A7_%E7%B2%89.png?Expires=1726474216&OSSAccessKeyId=LTAI4G56MHdEKBUNCHeRnQVi&Signature=2IF9K44FiUxafNF%2FP95I1HyuLfA%3D'
            ],
            ccptLevel:'',
        }
    },
    components: {
        vueQr,
    },
    methods: {
        cellStyle({ row, column, rowIndex, columnIndex }) {
            if (typeof (row.integrity) == 'string') {
                return {
                    'text-align': 'left',
                }
            }
        },
        cellStyle2({ row, column, rowIndex, columnIndex }) {
            if (typeof (row.centerPos) == 'string') {
                return {
                    'text-align': 'left',
                }
            }
        },
        /**
         * 
         * 通过给table传入span-method方法可以实现合并行或列，
         * 方法的参数是一个对象，里面包含当前行row、当前列column、当前行号rowIndex、当前列号columnIndex四个属性。
         * 该函数可以返回一个包含两个元素的数组，第一个元素代表rowspan，第二个元素代表colspan。
         *  也可以返回一个键名为rowspan和colspan的对象。
         */
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 1) {
                if (columnIndex === 0) {
                    return [1, 8];
                }
            }
        },
        arraySpanMethod2({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 1) {
                if (columnIndex === 0) {
                    return [1, 10];
                } else {
                    return [0, 0];
                }
            }
        },
        async getStuData() {
            this.tableData = []
            let data = {
                termKey: this.$route.query.termKey, // 20232,
                userId: this.$route.query.userId, // 390990,
                examType: this.$route.query.reportValue, // 1前测 2后测 4月测
                testId: this.$route.query.monthValue,
            }
            let resData = await this.$Api.Report.getStuData(data);
            // console.log('新版个人报告:', resData);
            this.userInfo = resData;
            this.rankNumber = resData.rankNumber;
            this.ccptLevel = resData.ccptLevel;
            this.reportName = resData.testName;
            this.workList = resData.imgList;
            this.userComment = resData.userComment;
            let integrity = resData.userComment.comment;
            let obj = { integrity };
            console.log('obj',obj);
            
            obj.integrity ? this.tableData.push(obj) : '';
            // this.tableData.push(obj);
            this.tableData.unshift(resData.userComment);

            let type = resData.userComment.dimVersion;
            // console.log('typetypetype', type);

            resData.wordList.map(item => {
                // 数据对象
                let obj = {
                    centerPos: item.centerPos,
                    gridSize: item.gridSize,
                    glyphGrav: item.glyphGrav,
                    rewindRel: item.rewindRel,
                    multiDist: item.multiDist,
                    strokeRel: item.strokeRel,
                    comboRel: item.comboRel
                };
                let obj3 = [
                    { prop: 'centerPos', name: '居中占位', value: item.centerPos },
                    { prop: 'gridSize', name: '占格大小', value: item.gridSize },
                    { prop: 'glyphGrav', name: '字形重心', value: item.glyphGrav },
                    { prop: 'rewindRel', name: '收放关系', value: item.rewindRel },
                    { prop: 'multiDist', name: '多画等距', value: item.multiDist },
                    { prop: 'strokeRel', name: '笔画关系', value: item.strokeRel },
                    { prop: 'comboRel', name: '组合关系', value: item.comboRel },
                ];
                // 单字点评
                let obj2 = {
                    centerPos: item.remark,
                    type: type
                }
                item.info = [obj, obj2];
                let arr = obj3.filter(item => {
                    return item.value > 0
                })
                item.head = arr
            })
            this.wordList = resData.wordList;
        },
        computeLevel(score) {
            if (score >= 85) {
                return '优秀'
            }
            if (score >= 70 && score < 85) {
                return '良好'
            }
            if (score >= 60 && score < 70) {
                return '合格'
            }
            if (score < 60) {
                return '待提升'
            }
        },
        //生成学生个人识写能力测评报告
        async getUserpdf() {
            let that = this;
            let data = {
                userId: this.$route.query.userId,
                termIndex: this.$route.query.termKey,
                examType: this.$route.query.reportValue,
                examId: this.$route.query.monthValue,
                version: this.$route.query.term_type
            }
            let resData = await this.$Api.DataScreen.getUserpdf(data);
            if (resData.code == 200) {
                this.reportStatus = 1;
                that.time = setInterval(() => {
                    that.downloadPdf('polling')
                }, 5000);
            } else {
                this.reportStatus = 1;
            }

            console.log('点击生成', resData)
        },
        //下载pdf
        async downloadPdf(type) {
            let data = {
                userId: this.$route.query.userId,
                termIndex: this.$route.query.termKey,
                examType: this.$route.query.reportValue,
                examId: this.$route.query.monthValue,
                version: this.$route.query.term_type
            }
            let resData = await this.$Api.DataScreen.getDownloadpdf(data);
            if (resData.data != null) {
                this.reportStatus = 2;
                if (this.time) {
                    clearInterval(this.time); // 清除定时器
                }
                if (type == 'btn') window.open(resData.data)
            }

        },
        showImagePreview(urlArr) {
            this.srcList = urlArr;
            this.$refs.imageRef.showViewer = true;
        },
    },
    mounted() {
        this.getStuData()
        this.currentTime = moment().format("YYYY.MM.DD");
        const currentUrl = window.location.href;
        const oldPart = 'NewReportZhuShouStu?';
        const newPart = 'NewReportZhuShouStuMove?';
        if (currentUrl.includes(oldPart)) {
            const newUrl = currentUrl.replace(oldPart, newPart);
            this.Top_up_balance = newUrl;
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./less/new_report_stu_zhushou.less');
</style>
